import { FC } from 'react';
import Image from 'next/image';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import flags from 'constants/flags';
import useTranslate from 'utils/i18n/useTranslate';
import { ILanguageMenuItem } from './language-menu-item.interface';
import { languageMenuItemStyles } from './language-menu-item.styles';

export const LanguageMenuItem: FC<ILanguageMenuItem> = ({ lang, onClick }) => {
  const { t, lang: currentLang } = useTranslate();

  const handleSelect = () => {
    onClick(lang);
  };

  return (
    <MenuItem sx={languageMenuItemStyles.item} onClick={handleSelect}>
      <ListItemIcon sx={languageMenuItemStyles.icon}>
        <Image
          src={flags[lang.toUpperCase()]}
          alt={`${t('img.flagOfAlt')} ${t(
            `languages.countries.${lang?.toUpperCase()}`,
          )}`}
          width={56}
          height={40}
          title={t(`languages.${lang}`)}
          loading="lazy"
        />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ sx: languageMenuItemStyles.itemText }}
        sx={{ ...(lang === currentLang && languageMenuItemStyles.isSelected) }}
      >
        {t(`languages.${lang}`)}
      </ListItemText>
    </MenuItem>
  );
};

import { filtersValuesFields } from 'constants/filters-values-fields';
import { fetchData } from '../helpers/fetchData';

export const hasCountryIdChanged = (data, dataToSave) => {
  return (
    Object.keys(dataToSave).includes(filtersValuesFields.country_id) &&
    data.country_id !== dataToSave.country_id
  );
};

export const getCountryData = async (country_id) => {
  const data = await fetchData(`country/${country_id}/view`);
  return data;
};

export const resetSalaryOnCountryChange = async (data) => {
  const { salary_min, salary_max_range } = await getCountryData(
    data.country_id,
  );
  return {
    ...data,
    salary_min: salary_min,
    salary_max: salary_max_range + salary_min,
  };
};

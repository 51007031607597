import { FC } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import NotificationIcon from 'public/images/icons/notifications-icon.svg';
import useTranslate from 'utils/i18n/useTranslate';
import { noNotificationsStyles } from './no-notifications.styles';

export const NoNotifications: FC = () => {
  const { t } = useTranslate();
  return (
    <Box sx={noNotificationsStyles.root}>
      <Image
        src={NotificationIcon}
        alt="Notifications with zero badge"
        loading="lazy"
      />
      <Box sx={noNotificationsStyles.texts}>
        <Typography sx={noNotificationsStyles.title}>
          {t('notifications.noNotifications.title')}
        </Typography>
        <Typography sx={noNotificationsStyles.subtitle}>
          {t('notifications.noNotifications.description')}
        </Typography>
      </Box>
    </Box>
  );
};

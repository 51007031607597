import { axiosForBuild } from '@nploy/shared';
import {
  ICandidateActivityJobDto,
  IFiltersValuesDto,
  ITopCompanyDto,
} from '@nploy/ui-infrastructure';
import {
  IGeneralInfoDto,
  IJobOccupationDto,
} from '@nploy/ui-infrastructure-recruiters';
import { fetchData } from 'utils/swr/helpers/fetchData';

export const fetchBuildData = async <T = any>(
  url: string,
  params?: Record<string, any>,
) => {
  const { data } = await axiosForBuild.get<T>(url, params && { params });
  return data;
};

export const getAllJobsWithFilters = async (params: Record<string, any>) => {
  const { data } = await fetchBuildData<{
    data: ICandidateActivityJobDto[];
  }>('jobs', {
    page: 1,
    per_page: 10000,
    ...params,
  });
  return data;
};

export const getAllJobsExternalWithFilters = async (
  params: Record<string, any>,
) => {
  const { data } = await fetchBuildData<{
    data: ICandidateActivityJobDto[];
  }>('jobs/scraper', {
    page: 1,
    per_page: 10000,
    ...params,
  });
  return data;
};

export const getJobsBySlugs = async (params: Record<string, any>) => {
  const { data } = await fetchBuildData('jobsBySlugs', {
    page: 1,
    per_page: 10000,
    ...params,
  });

  return data;
};

export const getJobCategories = async (
  locale: string,
): Promise<IJobOccupationDto[]> =>
  fetchBuildData<IJobOccupationDto[]>('list/job-occupations', {
    all: 1,
    locale,
  });

export const getFilters = async (): Promise<IGeneralInfoDto> =>
  fetchBuildData<IGeneralInfoDto>('list/general-info');

export const getTopCompanies = async () =>
  fetchBuildData<ITopCompanyDto[]>('list/companies', {
    all: 1,
  });

export const getAllCompanies = async () => fetchBuildData('all-companies');

export const getUserFiltersValues = async () =>
  fetchData<IFiltersValuesDto>('filters/general/get');

import { useEffect, useState } from 'react';
import { CandidatesRoutes } from 'constants/routes';
import { filtersValuesToSearchParams } from 'utils/ssr/filters-values-to-search-params';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useJobCategories } from 'utils/swr/hooks/useJobCategories';
import { useUser } from 'utils/swr/hooks/useUser';
import {
  useEmploymentLevelsQuery,
  useEmploymentTypesQuery,
} from 'store/services';

export const useHomePageJobsUrl = () => {
  const [jobsUrl, setJobsUrl] = useState<string>(CandidatesRoutes.jobs);
  const { filtersValues } = useFiltersValues();
  const { jobCategories } = useJobCategories();
  const { data: employmentLevels } = useEmploymentLevelsQuery();
  const { data: employmentTypes } = useEmploymentTypesQuery();
  const {
    user: { isAuthed },
  } = useUser();

  useEffect(() => {
    if (isAuthed) {
      setJobsUrl(`/${CandidatesRoutes.jobs}`);
    } else {
      const searchParams = filtersValuesToSearchParams({
        ...filtersValues,
        job_occupations: jobCategories.filter((category) =>
          filtersValues.jobCategories.includes(category.id),
        ),
        employment_levels: (employmentLevels || []).filter((level) =>
          filtersValues.employment_levels.includes(level.id),
        ),
        employment_types: (employmentTypes || []).filter((type) =>
          filtersValues.employment_types.includes(type.id),
        ),
      });

      setJobsUrl(`/${CandidatesRoutes.jobs}?${searchParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthed, filtersValues, employmentLevels]);

  return { jobsUrl };
};

import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { filtersValuesSearchParamsClassifier } from 'constants/job';
import { fromWorkModelSearchParamsToIds } from 'utils/helpers';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';

export const useFiltersWorkModel = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { saveWorkModels } = useFiltersValues();

  const {
    filters: { workModels },
  } = useFilters();
  const paramWorkModel = useSearchParams().getAll(
    filtersValuesSearchParamsClassifier.work_models,
  );

  const [open, setOpen] = useState(false);
  const [selectedWorkModels, setSelectedWorkModels] = useState<number[]>([]);

  useEffect(() => {
    if (paramWorkModel?.length) {
      setSelectedWorkModels(
        (paramWorkModel || []).map((wm) => fromWorkModelSearchParamsToIds[wm]),
      );
    } else {
      setSelectedWorkModels([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isOpenMenu, paramWorkModel?.length]);

  const handleToggleMenu = useCallback(() => {
    setIsOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsOpenMenu(false);
  }, []);

  const handleListKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLUListElement>) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    [],
  );

  const handleItemSelect = useCallback(
    (id: number) => {
      if (selectedWorkModels.includes(id)) {
        setSelectedWorkModels(
          selectedWorkModels.filter(
            (employmentTypeId) => employmentTypeId !== id,
          ),
        );
      } else {
        setSelectedWorkModels([...selectedWorkModels, id]);
      }
    },
    [selectedWorkModels],
  );

  const handleSave = useCallback(async () => {
    const validWorkModelsFormat = selectedWorkModels.map((modelId) =>
      workModels.find(({ id }) => id === modelId),
    );
    await saveWorkModels(validWorkModelsFormat);
    handleCloseMenu();
  }, [selectedWorkModels, saveWorkModels, handleCloseMenu, workModels]);

  return {
    open,
    isOpenMenu,
    selectedWorkModels,
    handleToggleMenu,
    handleCloseMenu,
    handleListKeyDown,
    handleItemSelect,
    handleSave,
    workModels,
  };
};

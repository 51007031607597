import { useRouter } from 'next/router';
import { useGetJobCategoriesQuery } from 'store/services';

export const useJobCategories = () => {
  const { locale } = useRouter();

  const { data } = useGetJobCategoriesQuery(locale);

  return {
    jobCategories: data || [],
  };
};

import { forwardRef } from 'react';
import { MenuItem, MenuList } from '@mui/material';
import { NoNotifications } from 'components/layout/Header/Notifications/no-notifications';
import NotificationItem from '../NotificationItem';
import { INotificationsMenu } from './notifications-menu.interface';
import { notificationsMenuStyles } from './notifications-menu.styles';

export const NotificationMenu = forwardRef<
  HTMLUListElement,
  INotificationsMenu
>(({ open, onListKeyDown, notifications }, ref) => (
  <MenuList
    ref={ref}
    autoFocusItem={open}
    onKeyDown={onListKeyDown}
    id="more-menu"
    aria-labelledby="more-menu-button"
    component="ul"
    sx={notificationsMenuStyles.menu}
  >
    {notifications?.length ? (
      notifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))
    ) : (
      <MenuItem>
        <NoNotifications />
      </MenuItem>
    )}
  </MenuList>
));

// Needed to forward refs for click away listener
NotificationMenu.displayName = 'NotificationMenu';

export default NotificationMenu;

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import euroUnion from 'public/images/euroUnion.png';
import fund from 'public/images/fund.png';
import logoFooter from 'public/images/logoFooter.svg';
import opportunity from 'public/images/opportunity.png';
import { dropdownNavLinks } from 'constants/dropdownNavLinks';
import useTranslate from 'utils/i18n/useTranslate';
import { footerStyles } from './footer.styles';

export const Footer: React.FC = () => {
  const { t } = useTranslate();

  return (
    <>
      <Box sx={footerStyles.footerWrapper}>
        <Container component="section">
          <>
            <Grid
              container
              spacing={{ xs: 2, md: 4 }}
              columns={{ xs: 1, sm: 1, md: 12 }}
            >
              <Grid item xs={1} sm={1} md={7} sx={footerStyles.logoPart}>
                <Link href="/">
                  <Image
                    src={logoFooter}
                    alt={t('navigation.nployLogo')}
                    width={120}
                    height={70}
                    title="nPloy"
                    loading="lazy"
                  />
                </Link>
                <Typography
                  component="span"
                  fontWeight={400}
                  fontSize={14}
                  sx={footerStyles.description}
                >
                  New Highers JSC is co-financed by the European Structural and
                  Investment Funds under the Operational Programme for
                  Innovation and Competitiveness 2014-2020 managed by the Fund
                  of Funds in Bulgaria through an investment by Morningside Hill
                  Capital Management.
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={5} sx={footerStyles.fund}>
                <Image
                  src={euroUnion}
                  alt={t('navigation.nployLogo')}
                  width={65}
                  height={57}
                  title="nPloy"
                  loading="lazy"
                />
                <Image
                  src={fund}
                  alt={t('navigation.nployLogo')}
                  width={170}
                  height={53}
                  title="nPloy"
                  loading="lazy"
                />
                <Image
                  src={opportunity}
                  alt={t('navigation.nployLogo')}
                  width={201}
                  height={53}
                  title="nPloy"
                  loading="lazy"
                />
              </Grid>
            </Grid>
            <Divider sx={footerStyles.divider} />
            <Box sx={footerStyles.list}>
              {Object.keys(dropdownNavLinks).map((item) => (
                <nav key={item}>
                  <List sx={{ margin: 0, padding: 0 }}>
                    <ListSubheader sx={footerStyles.listSubheader}>
                      {t(dropdownNavLinks[item].title)}
                    </ListSubheader>
                    {dropdownNavLinks[item].links.map((link) => (
                      <ListItemButton
                        component="a"
                        href={link.link}
                        key={link.link}
                        sx={footerStyles.listItem}
                      >
                        <ListItemText primary={t(link.text)} />
                      </ListItemButton>
                    ))}
                  </List>
                </nav>
              ))}
            </Box>
          </>
        </Container>
      </Box>
      <Box sx={footerStyles.copyright}>
        <Typography
          component="span"
          fontWeight={400}
          fontStyle="italic"
          fontSize={14}
        >
          Copyrights © 2024 All Rights Reserved by New Highers JSC.
        </Typography>
      </Box>
    </>
  );
};

export const dropdownNavLinks = {
  popularJobCategories: {
    title: 'navigation.popularJobCategories',
    links: [
      {
        link: '/jobs?job-category=it-and-technology',
        text: 'navigation.informationTechnologyJobs',
      },
      {
        link: '/jobs?job-category=client-and-customer-service',
        text: 'navigation.customerServiceJobs',
      },
      {
        link: '/jobs?job-category=marketing-and-communications-and-pr',
        text: 'navigation.marketingJobs',
      },
      {
        link: '/jobs?job-category=sales-and-trade',
        text: 'navigation.salesJobs',
      },
      {
        link: '/jobs?job-category=accounting-finance-and-statistics',
        text: 'navigation.accountingFinanceJobs',
      },
      {
        link: '/jobs?job-category=business-management-and-consulting',
        text: 'navigation.businessManagementJobs',
      },
    ],
  },
  forCompanies: {
    title: 'navigation.forCompanies',
    links: [
      {
        link: '/business/for-employers',
        text: 'navigation.forEmployers',
      },
      {
        link: '/business/ats-integrations',
        text: 'navigation.atsIntegrations',
      },
      {
        link: '/business/employer-branding',
        text: 'navigation.employerBranding',
      },
      {
        link: '/business/pricing',
        text: 'navigation.pricing',
      },
    ],
  },
  resources: {
    title: 'navigation.resources',
    links: [
      {
        link: '/blog',
        text: 'navigation.blog',
      },
    ],
  },
  aboutUs: {
    title: 'navigation.aboutUs',
    links: [
      {
        link: '/about-us/our-story',
        text: 'navigation.story',
      },
      {
        link: '/about-us/our-values',
        text: 'navigation.values',
      },
      {
        link: '/about-us/our-mobile-app',
        text: 'navigation.mobileApp',
      },
    ],
  },
  quickLinks: {
    title: 'navigation.quickLinks',
    links: [
      {
        link: '/get-in-touch',
        text: 'navigation.contactUs',
      },
      {
        link: '/terms-and-conditions',
        text: 'navigation.termsAndConditions',
      },
      {
        link: '/privacy-policy',
        text: 'navigation.privacyPolicy',
      },
    ],
  },
} as const;

const checkIfArraysAndLengthIsEuqal = (_arrA, _arrB) => {
  if (
    !Array.isArray(_arrA) ||
    !Array.isArray(_arrB) ||
    _arrA.length !== _arrB.length
  )
    return false;

  return true;
};

export const compareArrayDisregardPosition = (_arrA, _arrB) => {
  if (!checkIfArraysAndLengthIsEuqal(_arrA, _arrB)) return false;

  var arrA = _arrA.concat().sort();
  var arrB = _arrB.concat().sort();

  for (var i = 0; i < arrA.length; i++) {
    if (arrA[i] !== arrB[i]) return false;
  }

  return true;
};

const compareArray = (_arrA, _arrB) => {
  if (!checkIfArraysAndLengthIsEuqal(_arrA, _arrB)) return false;

  for (var i = 0; i < _arrA.length; i++) {
    if (_arrA[i] !== _arrB[i]) return false;
  }

  return true;
};

export const compareArraysById = (_arrA, _arrB, disregardPosition) => {
  if (!checkIfArraysAndLengthIsEuqal(_arrA, _arrB)) return false;

  const arrA = _arrA.map(({ id }) => id);
  const arrB = _arrB.map(({ id }) => id);

  if (disregardPosition) return compareArrayDisregardPosition(arrA, arrB);

  return compareArray(arrA, arrB);
};

export const compareArraysByFieldName = (
  _arrA,
  _arrB,
  disregardPosition,
  fieldName,
) => {
  if (!checkIfArraysAndLengthIsEuqal(_arrA, _arrB)) return false;

  const arrA = _arrA.map((item) => item[fieldName]);
  const arrB = _arrB.map((item) => item[fieldName]);

  if (disregardPosition) return compareArrayDisregardPosition(arrA, arrB);

  return compareArray(arrA, arrB);
};

import { validateCVSync } from 'validation/*';
import { forwardRef } from 'react';
import { useRouter } from 'next/router';
import { MenuList } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faFileUser,
  faSignOut,
  faWaveSine,
} from '@fortawesome/pro-regular-svg-icons';
import { CandidatesRoutes } from 'constants/routes';
import { firebaseCloudMessaging } from 'utils/helpers/webPush';
import { useAuth } from 'utils/swr/hooks/useAuth';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import {
  hideNotPermitted,
  openBuildCVModal,
  showNotPermitted,
} from 'store/reducers/uiReducer';
import { MoreMenuItem } from '../more-menu-item';
import { IMoreMenu } from './more-menu.interface';

export const MoreMenu = forwardRef<HTMLDivElement, IMoreMenu>(
  ({ open, onListKeyDown, onClose }, ref) => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { fullComplete: isCVCompleted, data: cv } = useAppSelector(
      ({ cv: stateCv }) => stateCv,
    );
    const { isValid } = validateCVSync(cv);
    const { signOut } = useAuth();
    const handleCVClick = () => {
      onClose();
      if (isCVCompleted || isValid || router.pathname === '/onboarding') {
        router.push(CandidatesRoutes.resume);
      } else {
        dispatch(openBuildCVModal());
      }
    };

    const handleActivityClick = () => {
      onClose();
      router.push(CandidatesRoutes.activity);
    };

    const handleSettings = () => {
      onClose();
      router.push(CandidatesRoutes.settings);
    };

    const handleLogout = async () => {
      try {
        dispatch(hideNotPermitted());
        await firebaseCloudMessaging.remove();
        onClose();
        signOut();
        await router.push(`/${CandidatesRoutes.jobs}`);
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(showNotPermitted());
      }
    };

    return (
      <MenuList
        ref={ref}
        autoFocusItem={open}
        onKeyDown={onListKeyDown}
        id="more-menu"
        aria-labelledby="more-menu-button"
        component="nav"
      >
        <MoreMenuItem
          icon={faFileUser as IconProp}
          text="cv"
          onClick={handleCVClick}
        />

        <MoreMenuItem
          icon={faWaveSine as IconProp}
          text="activity"
          onClick={handleActivityClick}
        />

        <MoreMenuItem
          icon={faCog as IconProp}
          text="settings"
          onClick={handleSettings}
        />

        <MoreMenuItem
          icon={faSignOut as IconProp}
          text="logout"
          onClick={handleLogout}
        />
      </MenuList>
    );
  },
);

// Needed to forward refs for click away listener
MoreMenu.displayName = 'MoreMenu';

import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { ButtonBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const CustomLink = ({
  children,
  href,
  className,
  variant = 'body2',
  component = 'span',
  target,
  onClick,
  external = false,
}) => {
  const classes = useStyles();

  // Return link
  if (href) {
    if (external) {
      return (
        <Link
          href={href}
          target={target}
          className={classes.link}
          rel="noreferrer"
        >
          <Typography
            className={classnames(classes.pointer, className)}
            variant={variant}
            component={component}
            color={'secondary'}
          >
            {children}
          </Typography>
        </Link>
      );
    } else {
      return (
        <Link href={href}>
          <Typography
            variant={variant}
            className={classnames(classes.pointer, className)}
            component={component}
            color={'secondary'}
          >
            {children}
          </Typography>
        </Link>
      );
    }
  }

  // Else render button
  return (
    <ButtonBase onClick={onClick} classes={{ root: classes.buttonBase }}>
      <Typography
        variant={variant}
        className={classnames(classes.pointer, className)}
        component={component}
        color={'secondary'}
      >
        {children}
      </Typography>
    </ButtonBase>
  );
};

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
  },
  pointer: {
    cursor: 'pointer',
  },
  buttonBase: { verticalAlign: 'top' },
}));

CustomLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  href: PropTypes.string,
  variant: PropTypes.string,
  component: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  external: PropTypes.bool,
};

export default CustomLink;

import React from 'react';
import PropTypes from 'prop-types';
import { DeepLinksModal } from '@nploy/ui/web';

const ModalWithDeepLinks = ({
  children,
  onClose,
  open,
  title,
  fixedWidth,
  titleComponent = 'h1',
  ...props
}) => {
  return (
    <DeepLinksModal
      open={open}
      title={title}
      fixedWidth={fixedWidth}
      titleComponent={titleComponent}
      {...props}
    >
      {children}
    </DeepLinksModal>
  );
};

ModalWithDeepLinks.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  fixedWidth: PropTypes.bool,
  titleComponent: PropTypes.string,
};

export default ModalWithDeepLinks;

import {
  ICandidateCountryFullDto,
  IEmploymentLevelDto,
  IEmploymentTypeDto,
  ISeniorityLevelDto,
} from '@nploy/ui-infrastructure';
import { IWorkModel } from '@nploy/ui/domain';
import { useGetFiltersQuery, useGetWorksModelsQuery } from 'store/services';
import { fetchData } from '../helpers/fetchData';

export const getFilters = async () => fetchData('list/general-info');

interface FiltersType {
  countries: ICandidateCountryFullDto[];
  employ_levels: IEmploymentLevelDto[];
  employ_types: IEmploymentTypeDto[];
  seniority_levels: ISeniorityLevelDto[];
  workModels: IWorkModel[];
}

export const useFilters = (): {
  filters: FiltersType;
} => {
  const { data } = useGetFiltersQuery();
  const { data: worksModels } = useGetWorksModelsQuery();

  return {
    filters: {
      ...(data || {
        countries: [],
        employ_levels: [],
        employ_types: [],
        seniority_levels: [],
      }),
      workModels: worksModels || [],
    },
  };
};

export const noNotificationsStyles = {
  root: {
    display: 'flex',
  },
  texts: {
    maxWidth: '240px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '11px',
    color: '#150090',
    marginBottom: '5px',
  },
  subtitle: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '11px',
    color: '#000000',
    textWrap: 'auto',
  },
};

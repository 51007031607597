/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, IconButton, Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { faCommentsAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import palette from 'theme/palette';
import { eventNames, logAnalyticsEvent } from 'utils/analytics/event';
import useTranslate from 'utils/i18n/useTranslate';
import { useUnreadMessages } from 'utils/swr/hooks/useUnreadMessages';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { openBuildCVModal, openLoginModal } from 'store/reducers/uiReducer';
import { Notifications } from 'components/layout/Header/Notifications';
import { HeaderIconButton } from 'components/layout/Header/header-icon-button';
import { DesktopLanguageMenu } from '../desktop-language-menu';
import { DesktopMoreMenu } from '../desktop-more-menu';
import { IRightPanel } from './right-panel.interface';
import { rightPanelStyles, useRightPanelStyles } from './right-panel.styles';

export const RightPanel: FC<IRightPanel> = ({
  isAuthed,
  image = '',
  openDrawer,
  loading,
}) => {
  const router = useRouter();
  const { t } = useTranslate();
  const classes = useRightPanelStyles();
  const dispatch = useAppDispatch();

  const { fullComplete: isCVCompleted, partialComplete } = useAppSelector(
    ({ cv }) => cv,
  );
  const { unreadMessages } = useUnreadMessages();

  const handleOpenLoginModal = () => {
    dispatch(openLoginModal());
  };

  const handleOpenBuildCVModal = () => {
    logAnalyticsEvent(eventNames.buildProfile);
    dispatch(openBuildCVModal());
  };

  const cvButtonProps = {
    color: 'secondary',
    className: classnames(classes.panelButton, classes.hideSmDown),
    onClick: handleOpenBuildCVModal,
    id: 'build-CV-button',
    'area-label': 'build-CV',
  };

  const loginButtonProps = {
    className: classnames(classes.signInButton),
    onClick: handleOpenLoginModal,
    id: 'open-login-button',
    'area-label': 'login-modal',
  };

  return (
    <Box sx={rightPanelStyles.rightPanel}>
      {!loading && (
        <>
          <>
            {isAuthed ? (
              <>
                {/* Authed */}
                {/* Build CV */}
                {router.pathname !== '/resume' &&
                  router.pathname !== '/onboarding' &&
                  !isCVCompleted && (
                    <Button {...cvButtonProps}>
                      {partialComplete
                        ? t('navigation.profileIs90')
                        : t('navigation.buildCV')}
                    </Button>
                  )}

                {/* Notifications */}
                <Notifications buttonClassName={classes.panelButton} />

                {/* Messages */}
                <Link href="/messages" passHref>
                  <HeaderIconButton
                    className={classes.panelButton}
                    itemsNumber={unreadMessages}
                    icon={faCommentsAlt as IconProp}
                  />
                </Link>

                {/* More menu */}
                <DesktopMoreMenu
                  image={image}
                  className={rightPanelStyles.panelButton}
                  containerClassName={rightPanelStyles.hideSmDown}
                />
              </>
            ) : (
              <>
                {/* Not authed */}
                {/* Login */}
                <Button {...loginButtonProps}>{t('navigation.signIn')}</Button>

                {/* HR login */}
                <Link
                  href="https://hr.nploy.net/login"
                  passHref
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography className={classes.hrLoginButton}>
                    {t('navigation.hrLogin')}
                  </Typography>
                </Link>

                {/* Create account */}
                {/* <Button {...registerButtonProps}>
                  {t('navigation.createAccount')}
                </Button> */}
              </>
            )}

            <DesktopLanguageMenu
              className={rightPanelStyles.panelButton}
              containerClassName={rightPanelStyles.hideSmDown}
            />
          </>
          {/* Menu only on sm down */}
          <IconButton
            sx={{
              ...rightPanelStyles.panelButton,
              ...rightPanelStyles.hideMdUp,
            }}
            onClick={openDrawer}
            id="navigation-menu-button"
            aria-label="navigation-menu"
          >
            <FontAwesomeIcon
              icon={faBars as IconProp}
              color={palette.black.main}
            />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default RightPanel;

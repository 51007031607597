import ADFlag from 'public/images/flags/ad.webp';
import AEFlag from 'public/images/flags/ae.svg';
import AFFlag from 'public/images/flags/af.webp';
import AGFlag from 'public/images/flags/ag.svg';
import AIFlag from 'public/images/flags/ai.webp';
import ALFlag from 'public/images/flags/al.svg';
import AMFlag from 'public/images/flags/am.svg';
import AOFlag from 'public/images/flags/ao.svg';
import AQFlag from 'public/images/flags/aq.svg';
import ARFlag from 'public/images/flags/ar.webp';
import ASFlag from 'public/images/flags/as.webp';
import ATFlag from 'public/images/flags/at.svg';
import AUFlag from 'public/images/flags/au.svg';
import AWFlag from 'public/images/flags/aw.webp';
import AXFlag from 'public/images/flags/ax.svg';
import AZFlag from 'public/images/flags/az.svg';
import BAFlag from 'public/images/flags/ba.svg';
import BBFlag from 'public/images/flags/bb.svg';
import BDFlag from 'public/images/flags/bd.svg';
import BEFlag from 'public/images/flags/be.svg';
import BFFlag from 'public/images/flags/bf.svg';
import BGFlag from 'public/images/flags/bg.svg';
import BHFlag from 'public/images/flags/bh.svg';
import BIFlag from 'public/images/flags/bi.svg';
import BJFlag from 'public/images/flags/bj.svg';
import BLFlag from 'public/images/flags/bl.svg';
import BMFlag from 'public/images/flags/bm.webp';
import BNFlag from 'public/images/flags/bn.webp';
import BOFlag from 'public/images/flags/bo.svg';
import BQFlag from 'public/images/flags/bq.svg';
import BRFlag from 'public/images/flags/br.webp';
import BSFlag from 'public/images/flags/bs.svg';
import BTFlag from 'public/images/flags/bt.webp';
import BVFlag from 'public/images/flags/bv.webp';
import BWFlag from 'public/images/flags/bw.svg';
import BYFlag from 'public/images/flags/by.svg';
import BZFlag from 'public/images/flags/bz.webp';
import CAFlag from 'public/images/flags/ca.svg';
import CCFlag from 'public/images/flags/cc.svg';
import CDFlag from 'public/images/flags/cd.svg';
import CFFlag from 'public/images/flags/cf.svg';
import CGFlag from 'public/images/flags/cg.svg';
import CHFlag from 'public/images/flags/ch.svg';
import CIFlag from 'public/images/flags/ci.svg';
import CKFlag from 'public/images/flags/ck.svg';
import CLFlag from 'public/images/flags/cl.svg';
import CMFlag from 'public/images/flags/cm.svg';
import CNFlag from 'public/images/flags/cn.svg';
import COFlag from 'public/images/flags/co.svg';
import CRFlag from 'public/images/flags/cr.svg';
import CUFlag from 'public/images/flags/cu.svg';
import CVFlag from 'public/images/flags/cv.svg';
import CWFlag from 'public/images/flags/cw.svg';
import CXFlag from 'public/images/flags/cx.svg';
import CYFlag from 'public/images/flags/cy.webp';
import CZFlag from 'public/images/flags/cz.svg';
import DEFlag from 'public/images/flags/de.svg';
import DJFlag from 'public/images/flags/dj.svg';
import DKFlag from 'public/images/flags/dk.svg';
import DMFlag from 'public/images/flags/dm.webp';
import DOFlag from 'public/images/flags/do.webp';
import DZFlag from 'public/images/flags/dz.svg';
import ECFlag from 'public/images/flags/ec.webp';
import EEFlag from 'public/images/flags/ee.svg';
import EGFlag from 'public/images/flags/eg.webp';
import EHFlag from 'public/images/flags/eh.webp';
import ERFlag from 'public/images/flags/er.svg';
import ESFlag from 'public/images/flags/es.webp';
import ETFlag from 'public/images/flags/et.svg';
import FIFlag from 'public/images/flags/fi.svg';
import FJFlag from 'public/images/flags/fj.webp';
import FKFlag from 'public/images/flags/fk.webp';
import FMFlag from 'public/images/flags/fm.svg';
import FOFlag from 'public/images/flags/fo.svg';
import FRFlag from 'public/images/flags/fr.svg';
import GAFlag from 'public/images/flags/ga.svg';
import GBFlag from 'public/images/flags/gb.svg';
import GDFlag from 'public/images/flags/gd.svg';
import GEFlag from 'public/images/flags/ge.svg';
import GFFlag from 'public/images/flags/gf.svg';
import GGFlag from 'public/images/flags/gg.svg';
import GHFlag from 'public/images/flags/gh.svg';
import GIFlag from 'public/images/flags/gi.webp';
import GLFlag from 'public/images/flags/gl.svg';
import GMFlag from 'public/images/flags/gm.svg';
import GNFlag from 'public/images/flags/gn.svg';
import GPFlag from 'public/images/flags/gp.svg';
import GQFlag from 'public/images/flags/gq.webp';
import GRFlag from 'public/images/flags/gr.svg';
import GSFlag from 'public/images/flags/gs.webp';
import GTFlag from 'public/images/flags/gt.webp';
import GUFlag from 'public/images/flags/gu.svg';
import GWFlag from 'public/images/flags/gw.svg';
import GYFlag from 'public/images/flags/gy.svg';
import HKFlag from 'public/images/flags/hk.svg';
import HMFlag from 'public/images/flags/hm.webp';
import HNFlag from 'public/images/flags/hn.svg';
import HRFlag from 'public/images/flags/hr.webp';
import HTFlag from 'public/images/flags/ht.svg';
import HUFlag from 'public/images/flags/hu.svg';
import IDFlag from 'public/images/flags/id.svg';
import IEFlag from 'public/images/flags/ie.svg';
import ILFlag from 'public/images/flags/il.svg';
import IMFlag from 'public/images/flags/im.webp';
import INFlag from 'public/images/flags/in.webp';
import IOFlag from 'public/images/flags/io.webp';
import IQFlag from 'public/images/flags/iq.svg';
import IRFlag from 'public/images/flags/ir.webp';
import ISFlag from 'public/images/flags/is.svg';
import ITFlag from 'public/images/flags/it.svg';
import JEFlag from 'public/images/flags/je.svg';
import JMFlag from 'public/images/flags/jm.svg';
import JOFlag from 'public/images/flags/jo.svg';
import JPFlag from 'public/images/flags/jp.svg';
import KEFlag from 'public/images/flags/ke.svg';
import KGFlag from 'public/images/flags/kg.svg';
import KHFlag from 'public/images/flags/kh.webp';
import KIFlag from 'public/images/flags/ki.svg';
import KMFlag from 'public/images/flags/km.svg';
import KNFlag from 'public/images/flags/kn.svg';
import KPFlag from 'public/images/flags/kp.svg';
import KRFlag from 'public/images/flags/kr.svg';
import KWFlag from 'public/images/flags/kw.svg';
import KYFlag from 'public/images/flags/ky.webp';
import KZFlag from 'public/images/flags/kz.webp';
import LAFlag from 'public/images/flags/la.svg';
import LBFlag from 'public/images/flags/lb.svg';
import LCFlag from 'public/images/flags/lc.svg';
import LIFlag from 'public/images/flags/li.webp';
import LKFlag from 'public/images/flags/lk.webp';
import LRFlag from 'public/images/flags/lr.svg';
import LSFlag from 'public/images/flags/ls.svg';
import LTFlag from 'public/images/flags/lt.svg';
import LUFlag from 'public/images/flags/lu.svg';
import LVFlag from 'public/images/flags/lv.svg';
import LYFlag from 'public/images/flags/ly.svg';
import MAFlag from 'public/images/flags/ma.svg';
import MCFlag from 'public/images/flags/mc.svg';
import MDFlag from 'public/images/flags/md.webp';
import MEFlag from 'public/images/flags/me.webp';
import MFFlag from 'public/images/flags/mf.svg';
import MGFlag from 'public/images/flags/mg.svg';
import MHFlag from 'public/images/flags/mh.svg';
import MKFlag from 'public/images/flags/mk.svg';
import MLFlag from 'public/images/flags/ml.svg';
import MMFlag from 'public/images/flags/mm.svg';
import MNFlag from 'public/images/flags/mn.svg';
import MOFlag from 'public/images/flags/mo.svg';
import MPFlag from 'public/images/flags/mp.webp';
import MQFlag from 'public/images/flags/mq.svg';
import MRFlag from 'public/images/flags/mr.svg';
import MSFlag from 'public/images/flags/ms.webp';
import MTFlag from 'public/images/flags/mt.webp';
import MUFlag from 'public/images/flags/mu.svg';
import MVFlag from 'public/images/flags/mv.svg';
import MWFlag from 'public/images/flags/mw.svg';
import MXFlag from 'public/images/flags/mx.webp';
import MYFlag from 'public/images/flags/my.svg';
import MZFlag from 'public/images/flags/mz.svg';
import NAFlag from 'public/images/flags/na.svg';
import NCFlag from 'public/images/flags/nc.svg';
import NEFlag from 'public/images/flags/ne.svg';
import NFFlag from 'public/images/flags/nf.webp';
import NGFlag from 'public/images/flags/ng.svg';
import NIFlag from 'public/images/flags/ni.webp';
import NLFlag from 'public/images/flags/nl.svg';
import NOFlag from 'public/images/flags/no.svg';
import NPFlag from 'public/images/flags/np.svg';
import NRFlag from 'public/images/flags/nr.svg';
import NUFlag from 'public/images/flags/nu.svg';
import NZFlag from 'public/images/flags/nz.svg';
import OMFlag from 'public/images/flags/om.webp';
import PAFlag from 'public/images/flags/pa.svg';
import PEFlag from 'public/images/flags/pe.svg';
import PFFlag from 'public/images/flags/pf.webp';
import PGFlag from 'public/images/flags/pg.svg';
import PHFlag from 'public/images/flags/ph.svg';
import PKFlag from 'public/images/flags/pk.svg';
import PLFlag from 'public/images/flags/pl.svg';
import PMFlag from 'public/images/flags/pm.svg';
import PNFlag from 'public/images/flags/pn.webp';
import PRFlag from 'public/images/flags/pr.svg';
import PSFlag from 'public/images/flags/ps.svg';
import PTFlag from 'public/images/flags/pt.webp';
import PWFlag from 'public/images/flags/pw.svg';
import PYFlag from 'public/images/flags/py.webp';
import QAFlag from 'public/images/flags/qa.svg';
import REFlag from 'public/images/flags/re.svg';
import ROFlag from 'public/images/flags/ro.svg';
import RSFlag from 'public/images/flags/rs.webp';
import RUFlag from 'public/images/flags/ru.svg';
import RWFlag from 'public/images/flags/rw.svg';
import SAFlag from 'public/images/flags/sa.webp';
import SBFlag from 'public/images/flags/sb.svg';
import SCFlag from 'public/images/flags/sc.svg';
import SDFlag from 'public/images/flags/sd.svg';
import SEFlag from 'public/images/flags/se.svg';
import SGFlag from 'public/images/flags/sg.svg';
import SHFlag from 'public/images/flags/sh.webp';
import SIFlag from 'public/images/flags/si.svg';
import SJFlag from 'public/images/flags/sj.svg';
import SKFlag from 'public/images/flags/sk.svg';
import SLFlag from 'public/images/flags/sl.svg';
import SMFlag from 'public/images/flags/sm.webp';
import SNFlag from 'public/images/flags/sn.svg';
import SOFlag from 'public/images/flags/so.svg';
import SRFlag from 'public/images/flags/sr.svg';
import SSFlag from 'public/images/flags/ss.svg';
import STFlag from 'public/images/flags/st.svg';
import SVFlag from 'public/images/flags/sv.webp';
import SXFlag from 'public/images/flags/sx.webp';
import SYFlag from 'public/images/flags/sy.svg';
import SZFlag from 'public/images/flags/sz.webp';
import TCFlag from 'public/images/flags/tc.webp';
import TDFlag from 'public/images/flags/td.svg';
import TFFlag from 'public/images/flags/tf.svg';
import TGFlag from 'public/images/flags/tg.svg';
import THFlag from 'public/images/flags/th.svg';
import TJFlag from 'public/images/flags/tj.svg';
import TKFlag from 'public/images/flags/tk.svg';
import TLFlag from 'public/images/flags/tl.svg';
import TMFlag from 'public/images/flags/tm.webp';
import TNFlag from 'public/images/flags/tn.svg';
import TOFlag from 'public/images/flags/to.svg';
import TRFlag from 'public/images/flags/tr.svg';
import TTFlag from 'public/images/flags/tt.svg';
import TVFlag from 'public/images/flags/tv.svg';
import TWFlag from 'public/images/flags/tw.svg';
import TZFlag from 'public/images/flags/tz.svg';
import UAFlag from 'public/images/flags/ua.svg';
import UGFlag from 'public/images/flags/ug.svg';
import UMFlag from 'public/images/flags/um.webp';
import USFlag from 'public/images/flags/us.webp';
import UYFlag from 'public/images/flags/uy.webp';
import UZFlag from 'public/images/flags/uz.svg';
import VAFlag from 'public/images/flags/va.webp';
import VCFlag from 'public/images/flags/vc.svg';
import VEFlag from 'public/images/flags/ve.svg';
import VGFlag from 'public/images/flags/vg.webp';
import VIFlag from 'public/images/flags/vi.webp';
import VNFlag from 'public/images/flags/vn.svg';
import VUFlag from 'public/images/flags/vu.svg';
import WFFlag from 'public/images/flags/wf.svg';
import WSFlag from 'public/images/flags/ws.svg';
import XKFlag from 'public/images/flags/xk.webp';
import YEFlag from 'public/images/flags/ye.svg';
import YTFlag from 'public/images/flags/yt.svg';
import ZAFlag from 'public/images/flags/za.svg';
import ZMFlag from 'public/images/flags/zm.svg';
import ZWFlag from 'public/images/flags/zw.svg';

export default {
  AD: ADFlag,
  AE: AEFlag,
  AF: AFFlag,
  AG: AGFlag,
  AI: AIFlag,
  AL: ALFlag,
  AM: AMFlag,
  AO: AOFlag,
  AQ: AQFlag,
  AR: ARFlag,
  AS: ASFlag,
  AT: ATFlag,
  AU: AUFlag,
  AW: AWFlag,
  AX: AXFlag,
  AZ: AZFlag,
  BA: BAFlag,
  BB: BBFlag,
  BD: BDFlag,
  BE: BEFlag,
  BF: BFFlag,
  BG: BGFlag,
  BH: BHFlag,
  BI: BIFlag,
  BJ: BJFlag,
  BL: BLFlag,
  BM: BMFlag,
  BN: BNFlag,
  BO: BOFlag,
  BQ: BQFlag,
  BR: BRFlag,
  BS: BSFlag,
  BT: BTFlag,
  BW: BWFlag,
  BV: BVFlag,
  BY: BYFlag,
  BZ: BZFlag,
  CA: CAFlag,
  CC: CCFlag,
  CD: CDFlag,
  CF: CFFlag,
  CG: CGFlag,
  CH: CHFlag,
  CI: CIFlag,
  CK: CKFlag,
  CL: CLFlag,
  CM: CMFlag,
  CN: CNFlag,
  CO: COFlag,
  CR: CRFlag,
  CU: CUFlag,
  CV: CVFlag,
  CW: CWFlag,
  CX: CXFlag,
  CY: CYFlag,
  CZ: CZFlag,
  DE: DEFlag,
  DJ: DJFlag,
  DK: DKFlag,
  DM: DMFlag,
  DO: DOFlag,
  DZ: DZFlag,
  EC: ECFlag,
  EE: EEFlag,
  EG: EGFlag,
  EH: EHFlag,
  ER: ERFlag,
  ES: ESFlag,
  ET: ETFlag,
  FI: FIFlag,
  FJ: FJFlag,
  FK: FKFlag,
  FM: FMFlag,
  FO: FOFlag,
  FR: FRFlag,
  GA: GAFlag,
  GB: GBFlag,
  EN: GBFlag,
  GD: GDFlag,
  GE: GEFlag,
  GF: GFFlag,
  GG: GGFlag,
  GH: GHFlag,
  GI: GIFlag,
  GL: GLFlag,
  GM: GMFlag,
  GN: GNFlag,
  GP: GPFlag,
  GQ: GQFlag,
  GR: GRFlag,
  GS: GSFlag,
  GT: GTFlag,
  GU: GUFlag,
  GW: GWFlag,
  GY: GYFlag,
  HK: HKFlag,
  HN: HNFlag,
  HM: HMFlag,
  HR: HRFlag,
  HT: HTFlag,
  HU: HUFlag,
  ID: IDFlag,
  IE: IEFlag,
  IL: ILFlag,
  IM: IMFlag,
  IN: INFlag,
  IO: IOFlag,
  IQ: IQFlag,
  IR: IRFlag,
  IS: ISFlag,
  IT: ITFlag,
  JE: JEFlag,
  JM: JMFlag,
  JO: JOFlag,
  JP: JPFlag,
  KE: KEFlag,
  KG: KGFlag,
  KH: KHFlag,
  KI: KIFlag,
  KM: KMFlag,
  KN: KNFlag,
  KP: KPFlag,
  KR: KRFlag,
  KW: KWFlag,
  KY: KYFlag,
  KZ: KZFlag,
  LA: LAFlag,
  LB: LBFlag,
  LC: LCFlag,
  LI: LIFlag,
  LK: LKFlag,
  LR: LRFlag,
  LS: LSFlag,
  LT: LTFlag,
  LU: LUFlag,
  LV: LVFlag,
  LY: LYFlag,
  MA: MAFlag,
  MC: MCFlag,
  MD: MDFlag,
  ME: MEFlag,
  MF: MFFlag,
  MG: MGFlag,
  MH: MHFlag,
  MK: MKFlag,
  ML: MLFlag,
  MM: MMFlag,
  MN: MNFlag,
  MO: MOFlag,
  MP: MPFlag,
  MQ: MQFlag,
  MR: MRFlag,
  MS: MSFlag,
  MT: MTFlag,
  MU: MUFlag,
  MV: MVFlag,
  MW: MWFlag,
  MX: MXFlag,
  MY: MYFlag,
  MZ: MZFlag,
  NA: NAFlag,
  NC: NCFlag,
  NE: NEFlag,
  NF: NFFlag,
  NG: NGFlag,
  NI: NIFlag,
  NL: NLFlag,
  NO: NOFlag,
  NP: NPFlag,
  NR: NRFlag,
  NU: NUFlag,
  NZ: NZFlag,
  OM: OMFlag,
  PA: PAFlag,
  PE: PEFlag,
  PF: PFFlag,
  PG: PGFlag,
  PH: PHFlag,
  PK: PKFlag,
  PL: PLFlag,
  PM: PMFlag,
  PN: PNFlag,
  PR: PRFlag,
  PS: PSFlag,
  PT: PTFlag,
  PW: PWFlag,
  PY: PYFlag,
  QA: QAFlag,
  RE: REFlag,
  RO: ROFlag,
  RS: RSFlag,
  RU: RUFlag,
  RW: RWFlag,
  SA: SAFlag,
  SB: SBFlag,
  SC: SCFlag,
  SD: SDFlag,
  SE: SEFlag,
  SG: SGFlag,
  SH: SHFlag,
  SI: SIFlag,
  SJ: SJFlag,
  SK: SKFlag,
  SL: SLFlag,
  SM: SMFlag,
  SN: SNFlag,
  SO: SOFlag,
  SR: SRFlag,
  SS: SSFlag,
  ST: STFlag,
  SV: SVFlag,
  SX: SXFlag,
  SY: SYFlag,
  SZ: SZFlag,
  TC: TCFlag,
  TD: TDFlag,
  TF: TFFlag,
  TG: TGFlag,
  TH: THFlag,
  TJ: TJFlag,
  TK: TKFlag,
  TL: TLFlag,
  TM: TMFlag,
  TN: TNFlag,
  TO: TOFlag,
  TR: TRFlag,
  TT: TTFlag,
  TV: TVFlag,
  TW: TWFlag,
  TZ: TZFlag,
  UA: UAFlag,
  UG: UGFlag,
  UM: UMFlag,
  US: USFlag,
  UY: UYFlag,
  UZ: UZFlag,
  VA: VAFlag,
  VC: VCFlag,
  VE: VEFlag,
  VG: VGFlag,
  VI: VIFlag,
  VN: VNFlag,
  VU: VUFlag,
  WF: WFFlag,
  WS: WSFlag,
  XK: XKFlag,
  YE: YEFlag,
  YT: YTFlag,
  ZA: ZAFlag,
  ZM: ZMFlag,
  ZW: ZWFlag,
};

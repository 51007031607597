import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';
import { setCVData } from 'store/actions/cvActions';
import { fetchDataWithPostRequest } from '../helpers/fetchData';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';

export const useCV = () => {
  const dispatch = useDispatch();
  const { data, error } = useSWR(
    shouldFetchIfHasToken() ? 'resume' : null,
    fetchDataWithPostRequest,
  );

  useEffect(() => {
    dispatch(setCVData(data));
  }, [data, dispatch]);

  return {
    cv: data,
    error,
  };
};

export const mutateCV = () => {
  mutate('resume');
};
